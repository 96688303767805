import React from 'react';
import SurveysSection from './SurveysSection'

const SurveysList = props => {

  let number = -1
  const surveys = props.surveys.map((survey, index) => {
    console.log('surveyasd')
    console.log(survey)
    console.log(index)
    number += 1
    // if(index === 0 || index === 1){return}
    return <SurveysSection
              survey={survey}
              key={survey.survey_id}
              index_number={number}
              updateSurvey={props.updateSurvey}
              deleteSurvey={props.deleteSurvey}
              />
  });
  return <div>{surveys}</div>
};
export default SurveysList;
