import React, { useState, useEffect } from 'react';
import { Header, Segment, Grid, Button, Loader } from 'semantic-ui-react';

import _ from 'lodash'

import NoAccess from  '../NoAccess'
import SurveyNew from './SurveyNew'
import SurveysList from './SurveysList'

import {fetchTrusts, update_Trust} from  '../../actions'
import { useDispatch, useSelector } from "react-redux";


const Surveys = () => {
  const dispatch = useDispatch();
  const trusts = useSelector(state => state.trusts);
  const user = useSelector(state => state.user);
  const [trust, update_trust] = useState('')
  const [surveys, update_surveys] = useState([])
  
  console.log(trust)

  useEffect(() => {
    dispatch(fetchTrusts())
    const trust_find = _.find(trusts, {trust_id: user['custom:trust']})
    console.log("trust_find")
    console.log(trust_find)
    update_trust(trust_find)
    update_surveys(JSON.parse(trust_find.surveys))

  }, []);

    function updateSurvey (survey, index) {
    console.log('updateSurvey')
    let new_survey_array = [...surveys]
    new_survey_array[index] = survey
    dispatch(update_Trust({id: trust.id, surveys: JSON.stringify(new_survey_array)}))

  }

  function deleteSurvey (index) {
    console.log('deleteSurvey')
    let new_survey_array = [...surveys]
    console.log(new_survey_array)
    new_survey_array.splice(index, 1)
    update_surveys(new_survey_array)
    dispatch(update_Trust({id: trust.id, surveys: JSON.stringify(new_survey_array)}))

  }

  function updateTrustState (trust) {
    update_trust(trust)
  }

    function handleUpdate () {
    const data = this.state.trust
    const params = {
      Item: data
    };
    dispatch(update_Trust(data))
  }





function displaySurveys () {
  if(trust.surveys === undefined){
    return <h3>No surveys on list</h3>
  }
  return <SurveysList
    surveys={surveys}
    updateSurvey={updateSurvey}
    deleteSurvey={deleteSurvey}
  />
}
function update_survey_array (array) {
  console.log('we are here')
  update_surveys(array)
}



  return (
    <div>
    <Grid columns='two' divided>
      <Grid.Row>
        <Grid.Column>
          <Header as='h2'>Surveys</Header>
          <Segment style={{textAlign: 'center', background: '#efefef'}} color='blue' >
            <SurveyNew
              trust={trust}
              user={user}
              updateTrustState={() => updateTrustState()}
              update_survey={update_survey_array}
            />
          </Segment>
            {displaySurveys()}
        </Grid.Column>
        <Grid.Column>
          <h2>Preview</h2>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
  )
}

export default Surveys




// import React from 'react';
// import { Header, Segment, Grid, Dimmer, Loader } from 'semantic-ui-react';
// import { connect } from 'react-redux';
// import _ from 'lodash';
// import { fetchTrusts, updateTrust } from '../../actions';

// import NoAccess from  '../NoAccess'
// import SurveyNew from './SurveyNew'
// import SurveysList from './SurveysList'

// class Surveys extends React.Component {
//   constructor(props) {
//   super(props);
//   this.state = {
//                 loading: true,
//               };
//   }

//   componentDidMount = async()=> {
//     await this.props.fetchTrusts(this.props.user['custom:trust']);
//     const trust = _.find(this.props.trusts, {trust_id: this.props.user['custom:trust']})
//     this.setState({
//       trust: trust,
//       loading: false
//     })
//   }

//   updateTrustState = (trust) => {
//     this.setState({trust: trust})
//   }

//   updateSurvey = (survey, index) => {
//     console.log('updateSurvey')
//     let new_survey_data = {...this.state.trust}
//     const new_surveys = _.set(new_survey_data.surveys, index, survey);
//     new_survey_data.surveys = new_surveys
//     this.setState({trust: new_survey_data})
//     this.handleUpdate()
//   }

//   deleteSurvey = (index) => {
//     console.log('deleteSurvey')
//     let new_survey_data = {...this.state.trust}
//     new_survey_data.surveys.splice(index, 1);
//     console.log(new_survey_data)
//     this.setState({trust: new_survey_data})
//     this.handleUpdate()
//   }


//   handleUpdate() {
//     const data = this.state.trust
//     const params = {
//       Item: data
//     };
//     this.props.updateTrust(params);
//   }

  // displaySurveys = () => {
  //   if(this.state.trust.surveys === undefined){
  //     return <h3>No surveys on list</h3>
  //   }
  //   return <SurveysList
  //     surveys={this.state.trust.surveys}
  //     updateSurvey={this.updateSurvey}
  //     deleteSurvey={this.deleteSurvey}
  //     />
  // }


//   render() {
//     if(this.props.user['custom:level'] !== 'super_admin'){ return  <NoAccess />}
//     const trust = _.find(this.props.trusts, {trust_id: this.props.user['custom:trust']})
//     if(this.state.loading === true){
//       return (
//         <div style={{marginLeft: 180}}>
//           <Header as='h2'>Surveys</Header>
//           <Dimmer active>
//             <Loader size='large' inverted content='Loading Surveys' />
//           </Dimmer>
//         </div>
//       )
//     }
//     return (
      // <div style={{marginLeft: 180}}>
      //   <Grid columns='two' divided>
      //     <Grid.Row>
      //       <Grid.Column>
      //         <Header as='h2'>Surveys</Header>
      //         <Segment style={{textAlign: 'center', background: '#efefef'}} color='blue' >
      //           <SurveyNew
      //             trust={trust}
      //             updateTrustState={this.updateTrustState.bind()}
      //           />
      //         </Segment>
      //           {this.displaySurveys()}
      //       </Grid.Column>
      //       <Grid.Column>
      //         <div>Preview</div>
      //       </Grid.Column>
      //     </Grid.Row>
      //   </Grid>
      // </div>

//     );
//   }
// }

// const mapStateToProps = state => {
//   return {  trusts: state.trusts,
//             user: state.user
//           };
// };

// export default connect(mapStateToProps, { fetchTrusts, updateTrust })(Surveys);
