import React, { Component } from 'react'
import { Accordion, Icon, Button, Divider } from 'semantic-ui-react'
import { connect } from 'react-redux';

import { updateTile, removeTile } from '../../actions';
import SurveysForm from './SurveysForm'


class SurveysSection extends Component {
  constructor(props) {
  super(props);
  this.file = null;
  this.state = {activeIndex: 1,
                title: this.props.survey.title,
                survey_id: this.props.survey.survey_id,
                open_count: this.props.survey.open_count,
                number_of_opens: this.props.survey.number_of_opens,
                survey_link: this.props.survey.survey_link,
                completed: this.props.survey.completed
              };

  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleChangeNumber = this.handleChangeNumber.bind(this);
  this.handleChangeId = this.handleChangeId.bind(this);
  }


  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index
    this.setState({ activeIndex: newIndex })
  }

  handleChange(event){
    const name = event.target.name
    this.setState({[name]: event.target.value});
  };

  handleChangePage(event){
    console.log(event.target.value)
  };

  handleChangeNumber(event){
    const index_int = Number(event.target.value)
    this.setState({number_of_opens: index_int});
  }

  handleChangeId(event){
    const survey_id = Number(event.target.value)
    this.setState({survey_id: survey_id});
  }


  handleSubmit(event) {
    event.preventDefault();
    this.props.updateSurvey({
      title: this.state.title,
      survey_id: this.state.survey_id,
      open_count: this.state.open_count,
      number_of_opens: this.state.number_of_opens,
      survey_link: this.state.survey_link,
      completed: this.state.completed
    }, this.props.index_number)
  }

  handleDelete = (event) => {
    this.props.deleteSurvey(this.props.index_number);
  }


  render() {
    const { activeIndex } = this.state
    return (

      <div>
        <Accordion styled style={{marginTop:10, background: '#fbfbfb'}}>
          <Accordion.Title
            active={activeIndex === 0}
            index={0}
            onClick={this.handleClick}
          >
            <Icon name='dropdown' />
              {this.state.title}
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            <SurveysForm
              onChange={this.handleChange.bind()}
              handleChangeNumber={this.handleChangeNumber.bind()}
              handleChangeId={this.handleChangeId.bind()}
              state={this.state}
              survey={this.state}
            />
            <Divider />
            <Button
              primary
              onClick={this.handleSubmit}
              content="Save"
            />
            <Button
              secondary
              onClick={this.handleDelete}
              content="delete"
            />
          </Accordion.Content>
        </Accordion>
      </div>
    )
  }
}

export default connect(null, { updateTile, removeTile })(SurveysSection);
