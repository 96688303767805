import React from 'react';
import { Form, Divider, Grid } from 'semantic-ui-react'

const SurveysForm = ({survey, onChange, handleChangeNumber, handleChangeId}) => {
  // https://www.surveymonkey.co.uk/r/RKSYSJ3
  console.log('title')
  console.log(survey)

  return (
    <Form>
      <Form.Field>
        <label>
          Name:
          <input
            name="title"
            type="text"
            value={survey.title}
            onChange={onChange}
          />
        </label>
        <Divider/>
          <Form>
            <Form.Field>
              <label>
                Survey Link
                <input
                  name="survey_link"
                  type="text"
                  value={survey.survey_link}
                  onChange={onChange}
                />
              </label>
            </Form.Field>
          </Form>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <Form>
                  <Form.Field>
                    <label>
                      Number of opens before trigger:
                      <input
                        name="open number"
                        type="number"
                        value={survey.number_of_opens}
                        onChange={handleChangeNumber}
                      />
                    </label>
                  </Form.Field>
                </Form>
              </Grid.Column>
              <Grid.Column width={8}>
                <Form>
                  <Form.Field>
                    <label>
                      Survey ID:
                      <input
                        name="Survey ID"
                        type="number"
                        value={survey.survey_id}
                        onChange={handleChangeId}
                      />
                    </label>
                    </Form.Field>
                  </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form.Field>
        </Form>
  )
};
export default SurveysForm;
