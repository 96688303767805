import React, { useState, useEffect } from 'react';
import { Button, Modal, Divider } from 'semantic-ui-react'
import { connect } from 'react-redux';

import moment from 'moment'

import { update_Trust } from '../../actions';
import SurveysForm from './SurveysForm'

import { useDispatch, useSelector } from "react-redux";



const SurveyNew = ({trust, user, update_survey}) => {

  const dispatch = useDispatch();

  const [title, update_title] = useState('')
  const [number_of_opens, update_number_of_opens] = useState(null)
  const [survey_id, update_survey_id] = useState(moment().unix())
  const [survey_link, update_survey_link] = useState('')
  const [modalOpen, update_modalOpen] = useState(false)

  function handleChange (event) {
    const name = event.target.name;
    if(name === 'title'){update_title(event.target.value)}
    if(name === 'survey_link'){update_survey_link(event.target.value)}
  }

  function handleChangeNumber (event) {
    const index_int = Number(event.target.value)
    update_number_of_opens(index_int)

  }

  function handleChangeId (event) {
    const index_int = Number(event.target.value)
//     this.setState({survey_id: index_int});
  update_survey_id(index_int)

    
  }
  function handleSubmit (){
        const new_survey = {
          title: title,
          survey_id: survey_id,
          open_count: 0,
          number_of_opens: number_of_opens,
          survey_link: survey_link,
          completed: false
        }
        
        let survery_array = JSON.parse(trust.surveys)
        console.log(survery_array)
        survery_array.push(new_survey)
        dispatch(update_Trust({id: trust.id, surveys: JSON.stringify(survery_array)}))
        update_survey(survery_array)
        resetState()
        update_modalOpen(false)
       
        }


  function resetState (){
    update_title('')
    update_number_of_opens(null)
    update_survey_id(moment().unix())
    update_survey_link('')
  }
  function handleOpen () {
    resetState()
    update_modalOpen(true)

  }

  return (
    <div>
      <Modal
        size='tiny'
        trigger={<Button primary onClick={() => handleOpen()}>New Survey</Button>}
        open={modalOpen}
        onClose={() => update_modalOpen(false)}
        >
        <Modal.Header>Add New Survey</Modal.Header>
        <Modal.Content>
          <SurveysForm
            onChange={handleChange}
            handleChangeNumber={handleChangeNumber}
            handleChangeId={handleChangeId}
            survey={{
              title: title, 
              number_of_opens: number_of_opens,
              survey_id: survey_id, 
              survey_link: survey_link }}

          />
              <Divider/>
              <Button
                primary
                onClick={() => handleSubmit()}
                content="Save"
              />
              <Button
                secondary
                onClick={() => update_modalOpen(false)}
                content="Close"
              />

        </Modal.Content>
      </Modal>
    </div>
  )
}

export default SurveyNew





// import React from 'react'
// import { Button, Modal, Divider } from 'semantic-ui-react'
// import { connect } from 'react-redux';

// import moment from 'moment'

// import { updateTrust } from '../../actions';
// import SurveysForm from './SurveysForm'

// class SurveyNew extends React.Component {
//   constructor(props) {
//   super(props);
//   this.state = {title: '',
//                 number_of_opens: null,
//                 survey_id: moment().unix(),
//                 survey_link: '',
//                 modalOpen: false,
//                 user: props.user
//               };
//   this.handleChange = this.handleChange.bind(this);
//   this.handleChangeNumber = this.handleChangeNumber.bind(this);
//   this.handleSubmit = this.handleSubmit.bind(this);
//   this.handleChangeId = this.handleChangeId.bind(this);
//   }

//   handleOpen = () => this.setState({
//     title: '',
//     number_of_opens: 0,
//     survey_link: '',
//     modalOpen: true,
//     survey_id: moment().unix()
//   })
//   handleClose = () => this.setState({ modalOpen: false })


//   handleChange(event) {
//     const name = event.target.name;
//     this.setState({[name]: event.target.value});
//   }
//   handleChangeNumber(event) {
//     const index_int = Number(event.target.value)
//     this.setState({number_of_opens: index_int});
//   }
//   handleChangeId (event) {
//     const index_int = Number(event.target.value)
//     this.setState({survey_id: index_int});
//   }


//   handleSubmit = (event) => {
//     event.preventDefault();
//     // const trust_object = {
//     //   timestamp: this.props.trust.timestamp,
//     //   trust_id: this.props.trust.trust_id,
//     //   name: this.props.trust.name,
//     //   image_url: this.props.trust.image_url,
//     //   trust_image: this.props.trust.trust_image,
//     //   active: this.props.trust.active,
//     //   surveys: this.props.trust.surveys
//     // }
//     // const new_survey = {
//     //   title: this.state.title,
//     //   survey_id: this.state.survey_id,
//     //   open_count: 0,
//     //   number_of_opens: this.state.number_of_opens,
//     //   survey_link: this.state.survey_link,
//     //   completed: false
//     // }
//     // trust.surveys.push(new_survey)
//     // console.log(trust)
//     // this.props.updateTrust({Item: trust});
//     // this.props.updateTrustState(trust)
//     // this.setState({ modalOpen: false,
//     //                 title: '',
//     //                 number_of_opens: null,
//     //                 survey_id: moment().unix(),
//     //                 survey_link: ''
//     //  })

//     console.log(this.props.trust)
//     }

// // app reads survey is present, then number of opens till trigger from beginning or since last survey.
//   render() {
//     return (
//       <Modal
//         size='tiny'
//         trigger={<Button primary onClick={this.handleOpen}>New Survey</Button>}
//         open={this.state.modalOpen}
//         onClose={this.handleClose}
//         >
//         <Modal.Header>Add New Survey</Modal.Header>
//         <Modal.Content>
//           <SurveysForm
//             onChange={this.handleChange.bind()}
//             handleChangeNumber={this.handleChangeNumber.bind()}
//             handleChangeId={this.handleChangeId.bind()}
//             state={this.state}
//           />
//               <Divider/>
//               <Button
//                 primary
//                 onClick={this.handleSubmit}
//                 content="Save"
//               />
//               <Button
//                 secondary
//                 onClick={this.handleClose}
//                 content="Close"
//               />

//         </Modal.Content>
//       </Modal>
//     )
//   }
// }
// const mapStateToProps = state => {
//   return { trusts: state.trusts, };
// };

// export default connect(mapStateToProps, { updateTrust })(SurveyNew);
